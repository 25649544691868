const Website1 = {
    title: "Website 1.0",

    date: "December 18, 2019",

    caption: "My first attempt at a website.",
    
    href: "",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/website1.0/website1.png')},
    ],

    description: (
        <>
            <p>
               
            </p>
        </>
    ),
}

export default Website1;