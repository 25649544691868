const Bracket = {
    title: "Guns 4 Roses Website",

    date: "January 30, 2024",

    caption: "Website for Guns & Roses tribute band.",
    
    href: "https://www.guns4roses.com/",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/guns4roses/guns4roses.png')},
    ],

    description: (
        <>
            <p>
            </p>
        </>
    ),
}

export default Bracket;