const Lathe = {
    title: "Custom Lathe",

    date: "December 20, 2020",

    caption: "SolidWorks lathe design.",
    
    href: "",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/lathe/lathe1.png')},
    ],

    description: (
        <>
            <p>
               
            </p>
        </>
    ),
}

export default Lathe;