const D3 = {
    title: "D3 CubeSat",

    date: "October 10, 2021",

    caption: "Drag Deorbit Device cubesat.",
    
    href: "",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/d3/d3.png')},
    ],

    description: (
        <>
            <p>
               
            </p>
        </>
    ),
}

export default D3;