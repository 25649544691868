const Mips = {
    title: "MIPS CPU",

    date: "April 30, 2020",

    caption: "VHDL implementation of a MIPS CPU.",
    
    href: "",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/mips/mips1.png')},
    ],

    description: (
        <>
            <p>
               
            </p>
        </>
    ),
}

export default Mips;