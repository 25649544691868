const TransferBoard = {
    title: "Transfer Board",

    date: "February 1, 2023",

    caption: "Stowable transfer board.",
    
    href: "",
        
    images: [
        {alt: "Image 1", src: require('./../../../assets/projects/transfer_board/transfer_board.png')},
    ],

    description: (
        <>
            <p>
               
            </p>
        </>
    ),
}

export default TransferBoard;